import axios from "axios";
import { Loading, Message } from "element-ui";
import Vue from "vue";
const options = {
  fullscreen: true,
  background: "rgba(0,0,0,0.1)",
};
// loading 实例
let loadingInstance = null;

axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";

// api 配置
let config = {
  baseURL: process.env.VUE_APP_API_ROOT,
  timeout: 200000, // Timeout
  withCredentials: true,
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    // 启动loading
    if (!config.loadingHide) {
      loadingInstance = Loading.service(options);
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    //关闭loading
    loadingInstance.close();
    if (response.status === 200 && response.error === "ERROR") {
      Message({
        type: "error",
        showClose: true,
        duration: 2000,
        message: response.message,
      });
      return Promise.reject(response);
    } else if (
      response.data.httpCode == 200 &&
      response.data.statusCode == 0 &&
      response.data.status == "success"
    ) {
      return response;
    } else {
      Message({
        type: "error",
        showClose: true,
        duration: 2000,
        message: response.data.message,
      });
      return Promise.reject(response.data);
    }
  },

  function (error) {
    loadingInstance.close();
    if (error && error.response && error.response.status) {
      switch (error.response.status) {
        case 500:
          Message({
            showClose: true,
            type: "error",
            duration: 2000,
            message: "[500]: 服务器错误~",
          });
          break;
        case 404:
          Message({
            showClose: true,
            type: "error",
            duration: 2000,
            message: "[404]: 资源不存在~",
          });
          break;
        case 400:
          Message({
            showClose: true,
            type: "error",
            duration: 2000,
            message: `${error.response.data.message}`,
          });
          break;
        default:
          Message({
            showClose: true,
            type: "error",
            duration: 2000,
            message: error.message,
          });
          break;
      }
    } else {
      Message({
        type: "error",
        showClose: true,
        duration: 2000,
        message: error.message,
      });
    }

    return Promise.reject(error);
  }
);

let Plugin = {};
Plugin.install = function (Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default _axios;
