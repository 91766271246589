import http from "@/plugins/http";

//获取合作类型下拉列表
export function getCoopreationOptions(data) {
  return http("web/message/type-list", {
    method: "POST",
    data,
  });
}
//合作加盟提交数据
export function saveCoopreationData(data) {
  return http("web/message/save", {
    method: "POST",
    data,
  });
}

//获取新闻列表
export function getNewsList(data) {
  return http("web/news/list", {
    method: "POST",
    data,
  });
}
//获取新闻详情
export function getNewsDetail(data) {
  return http("web/news/detail", {
    method: "POST",
    data,
  });
}

// 获取首页配置
export function getFixedConfig(data) {
  return http("web/fixed-setting", {
    method: "POST",
    data,
  });
}
