<template>
  <div class="home-enterprise-honor">
    <div class="honor-container">
      <div class="honor-top">
        <div class="honor-title">
          <div class="title-cn font-32 font-weight-600">{{ title_cn }}</div>
          <div class="title-en">{{ title_en }}</div>
          <img
            class="star-icon"
            src="../../assets/images/honor-star.png"
            alt=""
          />
        </div>
        <img
          class="honor-bg"
          src="../../assets/images/honor-bg-small.png"
          alt=""
        />
        <div class="pad-152 honor-list flex-between-center">
          <div class="honor-item font-16">
            <img
              class="honor-img"
              src="../../assets/images/honor01.png"
              alt=""
            />
            <div class="honor-text flex-center">
              <img
                class="prize-icon"
                src="../../assets/images/prize-icon.png"
                alt=""
              />
              <div class="prize-text font-weight-600">重点服务企业</div>
            </div>
          </div>
          <div class="honor-item font-16">
            <img
              class="honor-img"
              src="../../assets/images/honor02.png"
              alt=""
            />
            <div class="honor-text flex-center">
              <img
                class="prize-icon"
                src="../../assets/images/prize-icon.png"
                alt=""
              />
              <div class="prize-text font-weight-600">
                全国农业社会化服务创新试点
              </div>
            </div>
          </div>
          <div class="honor-item font-16">
            <img
              class="honor-img"
              src="../../assets/images/honor03.png"
              alt=""
            />
            <div class="honor-text flex-center">
              <img
                class="prize-icon"
                src="../../assets/images/prize-icon.png"
                alt=""
              />
              <div class="prize-text font-weight-600">
                农业产业化省重点龙头企业
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="honor-bottom flex-around-center">
        <img class="honor-bg" src="../../assets/images/honor-bg.png" alt="" />
        <div class="pad-70 honor-list flex-between-center">
          <div class="honor-item font-16">
            <img
              class="honor-img"
              src="../../assets/images/honor04.png"
              alt=""
            />
            <div class="honor-text flex-center">
              <img
                class="prize-icon"
                src="../../assets/images/prize-icon.png"
                alt=""
              />
              <div class="prize-text font-weight-600">
                农业产业化市重点龙头企业
              </div>
            </div>
          </div>
          <div class="honor-item font-16">
            <img
              class="honor-img"
              src="../../assets/images/honor05.png"
              alt=""
            />
            <div class="honor-text flex-center">
              <img
                class="prize-icon"
                src="../../assets/images/prize-icon.png"
                alt=""
              />
              <div class="prize-text font-weight-600">重点龙头企业</div>
            </div>
          </div>
          <div class="honor-item font-16">
            <img
              class="honor-img"
              src="../../assets/images/honor06.png"
              alt=""
            />
            <div class="honor-text flex-center">
              <img
                class="prize-icon"
                src="../../assets/images/prize-icon.png"
                alt=""
              />
              <div class="prize-text font-weight-600">
                河南省二十佳农业社会化服务组织
              </div>
            </div>
          </div>
          <div class="honor-item font-16">
            <img
              class="honor-img"
              src="../../assets/images/honor07.png"
              alt=""
            />
            <div class="honor-text flex-center">
              <img
                class="prize-icon"
                src="../../assets/images/prize-icon.png"
                alt=""
              />
              <div class="prize-text font-weight-600">邓州市市级龙头企业</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title_cn: {
      type: String,
      value: "企业荣誉",
    },
    title_en: {
      type: String,
      value: "ENTERPRISE HONOR",
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.home-enterprise-honor {
  width: 100%;
  background: #fff;
  padding-top: 50px;
  .honor-title {
    position: relative;
    margin-top: 15px;
    .title-cn {
      position: relative;
      z-index: 2;
    }
    .title-en {
      padding-top: 14px;
      font-family: "website";
      font-size: 52px;
      line-height: 68px;
      background: linear-gradient(#fff, #f2f2f2);
      background-clip: text;
      color: transparent;
      -webkit-text-fill-color: transparent;
      font-weight: 400;
      position: relative;
      top: -45px;
      z-index: 1;
    }
    .star-icon {
      position: relative;
      top: -35px;
    }
  }
  .honor-container {
    max-width: 1335px;
    margin: 0 auto;
    .honor-top {
      margin: 0 54px 0 67px;
      height: 424px;
      padding-bottom: 15px;
      position: relative;
      background-image: url("../../assets/images/leaf-left.png"),
        url("../../assets/images/leaf-right.png");
      background-repeat: no-repeat;
      background-position: left top, right top;
      background-size: contain;
      .honor-bg {
        position: absolute;
        left: 67px;
        bottom: -10px;
      }
    }
    .pad-152 {
      padding: 0 152px;
    }
    .pad-70 {
      padding: 0 70px;
    }
    .honor-list {
      margin-top: 50px;
      position: sticky;
      .honor-item {
        display: flex;
        flex-direction: column;
        justify-items: center;
        .honor-text {
          margin-top: 16px;
          .prize-text {
            color: #404040;
            margin-left: 8px;
          }
        }
      }
    }
    .honor-bottom {
      position: relative;
      .honor-bg {
        position: absolute;
        left: 0;
        bottom: -10px;
      }
    }
  }
}
</style>
