<template>
  <div class="com-header">
    <div class="navigation-bar">
      <div class="header-wrap">
        <h1 class="logo-h1">
          <a href="/" class="logo">
            <img src="@/assets/images/logo.png" alt="首邑智慧农业" />
          </a>
        </h1>
        <div class="top-keywords">
          提供整地｜播种｜施肥｜打药｜收割｜运输等一体化、精细化全程服务
        </div>
        <div class="contact">
          <div class="service-line">
            <img src="@/assets/images/phone-icon.png" class="phone-img" />
            <span>服务咨询热线</span>
          </div>
          <div class="phone-number">{{ mobile }}</div>
        </div>
      </div>
      <div class="nav-wrap">
        <ul>
          <li
            v-for="(item, index) in menu_list"
            :key="index"
            :class="active_index === index ? 'active' : ''"
            @click="clickMenu(item, index)"
          >
            {{ item.title }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { menu_list } from "@/constants/menu.js";
export default {
  name: "ComHeader",
  props: {
    active_index: {
      type: Number,
      default: -1,
    },
    mobile: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      menu_list,
    };
  },
  methods: {
    clickMenu(item, index) {
      if (item.href) {
        window.open(item.href, "_blank");
      } else {
        this.$emit("clickMenu", { item, index });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.com-header {
  width: 100vw;
  .navigation-bar {
    background-color: #fff;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    .header-wrap {
      width: 1200px;
      margin: 0 auto;
      height: 100px;
      position: relative;
      text-align: center;
      z-index: 2005;
      font-size: 16px;
      .logo-h1 {
        width: 159px;
        height: 47px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
      }
      .logo {
        display: inline-block;
        width: 100%;
        height: 100%;
        img {
          width: 159px;
          height: 47px;
        }
      }
      .top-keywords {
        position: absolute;
        top: 40px;
        left: 190px;
        font-size: 14px;
        line-height: 20px;
        color: #1c4332;
        font-weight: 600;
      }
      .contact {
        position: absolute;
        right: 0;
        top: 22px;
        .phone-img {
          width: 18px;
          height: 18px;
          vertical-align: middle;
          margin-right: 7px;
        }
        .service-line {
          font-size: 14px;
          font-weight: 400;
          color: #808080;
          line-height: 20px;
          text-align: right;
        }
        .phone-number {
          height: 32px;
          font-size: 24px;
          font-family: Bebas;
          color: #1c4332;
          line-height: 32px;
          text-align: right;
          font-weight: 600;
        }
      }
    }
    .nav-wrap {
      width: 100%;
      height: 40px;
      background: #286141;
      ul {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        li {
          height: 40px;
          width: 150px;
          line-height: 40px;
          color: #fff;
          cursor: pointer;
          transition: all 0.2s;
          &:hover {
            background: #22834c;
            transition: all 0.2s;
          }
          &.active {
            background: #22834c;
          }
        }
      }
    }
  }
}
</style>
