<template>
  <div class="home-business-component">
    <div class="title">
      <div class="title-cn font-32 font-weight-600">{{ title_cn }}</div>
      <div class="title-en">{{ title_en }}</div>
    </div>
    <div class="main-container">
      <div class="direction-left flex-between" v-if="direction === 'left'">
        <img src="@/assets/images/wraping.png" class="business-img" />
        <div class="flex-end">
          <div>
            <div class="center-title flex-end-center">
              <div class="dot"></div>
              <span class="font-bold">饲料贸易</span>
            </div>
            <div class="center-text text-right">
              青储饲料产业化，提供小麦秸秆、苜蓿、青储玉米等青储机械化收割，裹包业务
            </div>
          </div>
          <div class="business-list">
            <div class="business-item flex-between-center">
              <div class="item-info">
                <div class="cn-name">裹包</div>
                <div class="en-name">Wrapping</div>
              </div>
              <img src="@/assets/images/wrapping-icon.png" class="item-img" />
            </div>
            <div class="business-item flex-between-center">
              <div class="item-info">
                <div class="cn-name">打捆</div>
                <div class="en-name">Baling</div>
              </div>
              <img src="@/assets/images/bundling-icon.png" class="item-img" />
            </div>
            <div class="business-item flex-between-center">
              <div class="item-info">
                <div class="cn-name">青贮</div>
                <div class="en-name">Green Fodder</div>
              </div>
              <img src="@/assets/images/investment-icon.png" class="item-img" />
            </div>
          </div>
        </div>
      </div>
      <div class="direction-right flex-between" v-if="direction === 'right'">
        <div class="flex-start">
          <div class="business-list">
            <div class="business-item flex-start-center">
              <img src="@/assets/images/seeds-icon.png" class="item-img" />
              <div class="item-info">
                <div class="cn-name">种子</div>
                <div class="en-name">Seeds</div>
              </div>
            </div>
            <div class="business-item flex-start-center">
              <img src="@/assets/images/fertilizer-icon.png" class="item-img" />
              <div class="item-info">
                <div class="cn-name">化肥</div>
                <div class="en-name">Fertilizer</div>
              </div>
            </div>
            <div class="business-item flex-start-center">
              <img src="@/assets/images/pesticides-icon.png" class="item-img" />
              <div class="item-info">
                <div class="cn-name">农药</div>
                <div class="en-name">Pesticides</div>
              </div>
            </div>
          </div>
          <div>
            <div class="center-title flex-start-center">
              <div class="dot"></div>
              <span class="font-bold">农资贸易</span>
            </div>
            <div class="center-text text-left">
              提供种子、化肥、农药等农资用品零售，批发等贸易。
            </div>
          </div>
        </div>
        <img src="@/assets/images/seeds.png" class="business-img" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title_cn: {
      type: String,
      value: "饲料贸易",
    },
    title_en: {
      type: String,
      value: "Feed Trade",
    },
    direction: {
      type: String,
      value: "left",
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.home-business-component {
  width: 100%;
  background: #fff;
  .title {
    .title-cn {
      position: relative;
      z-index: 2;
    }
    .title-en {
      padding-top: 14px;
      font-family: "website";
      font-size: 52px;
      line-height: 69px;
      background: linear-gradient(#fff, #f2f2f2);
      background-clip: text;
      color: transparent;
      -webkit-text-fill-color: transparent;
      font-weight: 400;
      position: relative;
      top: -45px;
      z-index: 1;
    }
  }
  .main-container {
    width: 1200px;
    margin: 0 auto;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.05);
    .business-img {
      width: 393px;
      height: 391px;
    }
    .center-title {
      margin-top: 96px;
      span {
        font-size: 18px;
        font-weight: 500;
        color: #201f1f;
        line-height: 25px;
      }
    }
    .dot {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #22834c;
      margin-right: 12px;
    }
    .center-text {
      width: 341px;
      font-size: 16px;
      font-weight: 400;
      color: #414141;
      line-height: 22px;
      margin-top: 18px;
    }
    .business-list {
      width: 262px;
      margin-left: 71px;
      font-size: 18px;
      border-left: 1px solid #f5f8f6;
      .business-item {
        height: 131px;
        padding-left: 22px;
        padding-right: 30px;
        border: 1px solid #f5f8f6;
        text-align: left;
        position: relative;
        &::before {
          content: "";
          display: block;
          width: 4px;
          height: 20px;
          background: #22834c;
          position: absolute;
          top: 53px;
          left: 0;
        }
        .cn-name {
          font-weight: 500;
          color: #201f1f;
          line-height: 25px;
        }
        .en-name {
          font-weight: 400;
          color: #a5a5a5;
          line-height: 25px;
          margin-top: 5px;
        }
        .item-img {
          width: 40px;
          height: 40px;
        }
      }
    }
    .direction-right {
      .business-list {
        margin-left: 0;
        margin-right: 63px;
        .business-item::before {
          left: auto;
          right: 0;
        }
        .item-img {
          margin-right: 16px;
        }
      }
    }
  }
}
</style>
