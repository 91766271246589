<template>
  <div class="website-wrap" ref="wrap">
    <!-- 顶部 -->
    <com-header
      :active_index="active_index"
      :mobile="setting.company_mobile"
      @clickMenu="clickMenu"
    ></com-header>
    <!-- 内容区域 -->
    <div class="home-container" ref="home">
      <!-- 视频 -->
      <div class="video-fill" ref="videoWrap">
        <video
          style="position: absolute; left: 0; top: 0; z-index: -2; width: 100%"
          :autoplay="true"
          muted
          loop
          class="position-center"
          preload="load"
          ref="autoVideo"
          :src="video_url"
        ></video>
        <div class="img-title">
          <div class="img-title-main">
            <h2>颠覆传统农业作业方式</h2>
            <h2>Overturn the traditional agricultural</h2>
            <h2>operation mode</h2>
          </div>
          <div class="play">
            <img :src="img.play" alt="" @click="play" />
          </div>
        </div>
        <div class="scroll">
          <span></span>
          <div class="scroll-item">鼠标滑动向下</div>
          <span></span>
        </div>
        <div class="banner-bg">
          <img :src="img.bnBg" alt="bg" />
        </div>
      </div>
      <!-- 关于我们 -->
      <div class="moduel aboutme" ref="about">
        <div class="moduel-title">
          <span>关于首邑</span>
          <p>ABOUT SHOUYI</p>
        </div>
        <div class="moduel-main">
          <el-carousel :interval="5000" arrow="never" height="500px">
            <el-carousel-item>
              <div class="about-item flex-between">
                <div class="module-left">
                  <div class="sub-title">服务范围</div>
                  <div class="service-province">
                    服务范围遍布内蒙、河北、河南、湖北、湖南、山东、安徽、四川、广西、云南等10省，30余县市。
                  </div>
                  <div class="total-service">
                    截至目前已签约托管服务土地
                    62万余亩，在非平台型农业社会化服务行业处领先地位。
                  </div>
                </div>
                <div class="module-right">
                  <img src="@/assets/images/work-map.png" class="map-img" />
                </div>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div class="about-item flex-between">
                <div class="module-left">
                  <div class="company-info">
                    {{
                      setting.company_name
                    }}成立于2016年6月，是一家持有农机装备开展农业社会化服务的公司。公司以提高农业生产效率为导向，以标准化农业耕作模式为示范，以农业工业化为实践准则，以农业数字化、信息化为抓手，以解决大田农业经营者痛点需求为己任，不断提升公司服务水准，实现客户最大价值。为更好的服务不同区域的客户，先后成立了宝丰县首邑农业有限公司、西平县首邑农业有限公司、柘城县首邑农业有限公司、山东首邑农业有限公司、湖北首邑农业有限公司，上海首邑农业发展有限公司，北京首邑农业发展有限公司。
                  </div>
                  <div class="info-list">
                    <div
                      class="info-item"
                      v-for="(item, index) in company_info"
                      :key="index"
                    >
                      <div class="year">
                        <span class="year-number">{{ item.info }}</span>
                        <span class="unit">{{ item.unit }}</span>
                      </div>
                      <div class="event">{{ item.title }}</div>
                    </div>
                  </div>
                </div>
                <div class="module-right">
                  <img
                    src="@/assets/images/about_shouyi02.png"
                    alt="关于首邑"
                  />
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <!-- 企业风采 -->
      <div class="elegant-demeanor" ref="elegant">
        <div class="moduel-title elegant-title">
          <span>企业风采</span>
          <p>Corporate Style</p>
        </div>
        <div class="elegant-carousel">
          <el-carousel :interval="5000" arrow="always">
            <el-carousel-item
              v-for="(item, index) in carousel_list"
              :key="index"
            >
              <img :src="item" alt="img" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <!-- 农业服务 -->
      <div class="moduel agriculture" ref="agriculture">
        <div class="moduel-title elegant-title">
          <span>农业综合服务商</span>
          <p>AGRICULTURAL SERVIES</p>
        </div>
        <div class="middle-content">
          <div class="service-list flex-start">
            <div
              class="service-item"
              v-for="(item, index) in service_list"
              :key="index"
            >
              <img v-if="item.type === 1" :src="item.url" class="service-img" />
              <div v-else class="text-center">
                <div class="flex-start-center text" v-if="item.type === 2">
                  <img
                    src="@/assets/images/arrow-left.png"
                    class="arrow-left"
                  />
                  <div class="service-name">{{ item.service_name }}</div>
                </div>
                <div class="flex-start-center text" v-if="item.type === 3">
                  <div class="service-name">{{ item.service_name }}</div>
                  <img
                    src="@/assets/images/arrow-right.png"
                    class="arrow-right"
                  />
                </div>
                <div class="service-info">{{ item.service_info }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="middle">
        <!-- 代耕服务 -->
        <div class="moduel integrated-service" ref="serve">
          <div class="moduel-title">
            <span class="font-32">代耕服务</span>
            <p class="font-weight-400">Contract farming service</p>
          </div>
          <div class="middle-content">
            <div class="service-list">
              <div class="list-left">
                <div
                  class="left-item"
                  :class="{ 'active-bor': index == unify_index }"
                  v-for="(item, index) in unify_list"
                  :key="index"
                  @click="changeUnify(index)"
                >
                  <img
                    class="item-icon"
                    :src="
                      'https://mp-resource.shouyinongye.com/resource/shouyi/pc/ser-icon' +
                      item.id +
                      '.png'
                    "
                  />
                  <div>
                    <div class="name">{{ item.name }}</div>
                    <div class="item-text">{{ item.text }}</div>
                  </div>
                </div>
              </div>
              <div class="list-right">
                <div class="infos flex">
                  <div class="spot"></div>
                  <div class="container">
                    <div class="name">
                      {{ unify_list[unify_index].name }}
                    </div>
                    <div class="content">
                      {{ unify_list[unify_index].content }}
                    </div>
                  </div>
                  <img
                    class="num"
                    :src="
                      'https://mp-resource.shouyinongye.com/resource/shouyi/pc/num' +
                      unify_list[unify_index].id +
                      '.png'
                    "
                  />
                  <div class="tip">{{ unify_list[unify_index].tip }}</div>
                </div>
                <img class="right-img" :src="unify_list[unify_index].img" />
              </div>
            </div>
          </div>
        </div>
        <div class="feed-trade">
          <HomeBusiness
            title_cn="饲料贸易"
            title_en="Feed Trade"
            direction="left"
          ></HomeBusiness>
        </div>
        <div class="feed-trade">
          <HomeBusiness
            title_cn="农资贸易"
            title_en="Agricultural Trade"
            direction="right"
          ></HomeBusiness>
        </div>
      </div>
      <!-- 企业荣誉 -->
      <div ref="awards">
        <HomeEnterpriseHonor
          title_cn="企业荣誉"
          title_en="ENTERPRISE HONOR"
        ></HomeEnterpriseHonor>
      </div>
      <!-- 新闻资讯 -->
      <div class="moduel news" ref="news">
        <div class="moduel-title">
          <span>新闻资讯</span>
          <p>NEWS INFORMATION</p>
        </div>
        <div class="middle-content">
          <div class="left-news" @click="goNewsDetail(first_news)">
            <div class="news-img">
              <img :src="first_news.img_url" />
            </div>
            <div class="title character-hiding-two">
              {{ first_news.title }}
            </div>
            <div class="bottom-info">
              <div class="pulish-date">
                {{ first_news.created_at }}
              </div>
              <div class="read-more">阅读全文</div>
            </div>
          </div>
          <div class="right-news">
            <div
              class="news-item"
              v-for="(item, index) in news_list"
              :key="index"
              @click="goNewsDetail(item)"
            >
              <img class="new-img" :src="item.img_url" alt="" />
              <div class="infos">
                <div class="news-title character-hiding-two">
                  {{ item.title }}
                </div>
                <div class="content character-hiding-two">
                  {{ item.introduction }}
                </div>
                <div class="news-bottom">
                  <div class="date">{{ item.created_at }}</div>
                  <div class="read-more">阅读全文</div>
                </div>
              </div>
            </div>
            <div class="look-more" @click="goPage('news-list')">
              <span>查看更多</span>
              <img src="@/assets/images/double-arrow.png" class="more-icon" />
            </div>
          </div>
        </div>
      </div>
      <!-- 商务合作 -->
      <div class="moduel cooperation" ref="cooperation">
        <div class="moduel-title">
          <span>商务合作</span>
          <p>DISTRIBUTOR FRANCHISE</p>
        </div>
        <div class="moduel-main">
          <div class="form">
            <el-form
              :model="form"
              status-icon
              :rules="rules"
              ref="form"
              label-position="top"
            >
              <el-form-item label="公司名称" prop="company_name">
                <el-input v-model="form.company_name" maxlength="20"></el-input>
              </el-form-item>
              <el-form-item label="姓名" prop="contact">
                <el-input v-model="form.contact" maxlength="20"></el-input>
              </el-form-item>
              <el-form-item label="手机号" prop="mobile">
                <el-input
                  v-model="form.mobile"
                  maxlength="20"
                  @input="inputPhone"
                  @blur="blurPhone"
                ></el-input>
              </el-form-item>
              <el-form-item label="合作类型" prop="cooperation_id">
                <el-select
                  v-model="form.cooperation_id"
                  placeholder="请选择合作类型"
                >
                  <el-option
                    v-for="(item, index) in typeList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="留言" prop="message">
                <el-input v-model="form.message" maxlength="100"></el-input>
              </el-form-item>
              <el-form-item style="margin-top: 70px">
                <el-button type="primary" @click="submitForm('form')"
                  >提交</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <!-- 联系我们 -->
      <div class="moduel contact" ref="contact">
        <div class="moduel-title">
          <span>联系我们</span>
          <p>CONTACT US</p>
        </div>
        <CompanyMap
          v-if="setting.company_name"
          :latitude="setting.company_latitude"
          :longitude="setting.company_longitude"
          :address="setting.company_name"
        ></CompanyMap>
        <div class="moduel-main">
          <div class="item">
            <img src="@/assets/images/address.png" />
            <div class="title">公司地址</div>
            <span>{{ setting.company_address }}</span>
          </div>
          <div class="split-line"></div>
          <div class="item">
            <img src="@/assets/images/contact.png" />
            <div class="title">服务热线</div>
            <span>{{ setting.company_mobile }}</span>
          </div>
          <div class="split-line"></div>
          <div class="item">
            <img src="@/assets/images/email.png" />
            <div class="title">公司邮箱</div>
            <span>{{ setting.company_email }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- footer -->
    <com-footer
      :mobile="setting.company_mobile"
      @clickMenu="clickMenu"
    ></com-footer>
    <!-- 视频播放弹窗 -->
    <transition name="el-zoom-in-center">
      <div class="screen" v-show="is_play">
        <i class="el-icon-close" @click="closeDialog"></i>
        <div id="cover-video ">
          <video
            width="1028"
            height="578"
            ref="video"
            class="position-center"
            controls
            controlsList="nodownload"
            preload="load"
            :src="video_url"
          ></video>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
const resource_url = "https://mp-resource.shouyinongye.com/resource";
import ComHeader from "@/components/layout/ComHeader";
import ComFooter from "@/components/layout/ComFooter";
import HomeBusiness from "@/components/business/home-business";
import CompanyMap from "@/components/business/company-map";
import { menu_list } from "@/constants/menu.js";
import {
  getCoopreationOptions,
  saveCoopreationData,
  getNewsList,
  getFixedConfig,
} from "@/api/home.js";
import HomeEnterpriseHonor from "../components/business/home-enterprise-honor.vue";
export default {
  data() {
    return {
      video_url: `${resource_url}/video/home08.mp4`,
      carousel_list: [
        require("@/assets/images/carousel01.png"),
        require("@/assets/images/carousel02.jpg"),
        require("@/assets/images/carousel03.jpg"),
        require("@/assets/images/carousel04.jpg"),
        require("@/assets/images/carousel05.jpg"),
        require("@/assets/images/carousel06.jpg"),
        require("@/assets/images/carousel07.png"),
        require("@/assets/images/carousel08.png"),
      ], // 企业风采数据
      service_list: [
        { type: 1, url: require("@/assets/images/agricultural-service01.png") },
        {
          type: 2,
          service_name: "耕作业务部",
          service_info: "农业产前、产中、产后等耕种管收及烘干仓储服务",
        },
        { type: 1, url: require("@/assets/images/agricultural-service02.png") },
        {
          type: 2,
          service_name: "农资及粮贸业务部",
          service_info: "对全程托管客户实行一站式保姆服务",
        },
        {
          type: 3,
          service_name: "牧草业务部",
          service_info: "秸秆打捆、青（黄）贮收割裹包",
        },
        { type: 1, url: require("@/assets/images/agricultural-service03.png") },
        {
          type: 3,
          service_name: "农业数字化推广部",
          service_info: "面向重点客户推广农业数字化新兴农业服务",
        },
        { type: 1, url: require("@/assets/images/agricultural-service04.png") },
      ],
      unify_list: [
        {
          name: "耕种作业",
          text: "Farming operation",
          content:
            "机具可精准地在作物行间行走，深耕，作业时不碰苗，不伤苗，三维定位播种模式，保证壮苗齐苗，达到减肥减药和增产增效的绿色化农业生产的目的。",
          id: 1,
          img: require("@/assets/images/service01.png"),
        },
        {
          name: "洒药作业",
          text: "Spraying operation",
          content:
            "农林植物保护作业无人机通过地面遥控GPS飞控实现喷洒作业，可喷洒药剂、种子、粉剂等。替代人力，安全性高；节省资源，降低污染；防控效果好。",
          id: 2,
          img: require("@/assets/images/service02.png"),
        },
        {
          name: "播种作业",
          text: "Seeding operation",
          content:
            "机械播种作业主要是利用拖拉机牵引播种机进行播种作业。播种机的工作部件包括排种器、排肥器、开沟器、输种管、覆土和镇压装置等。",
          id: 3,
          img: require("@/assets/images/service03.png"),
        },
        {
          name: "收割作业",
          text: "Harvesting operation",
          content:
            "收割作业采用机械式收割机，一次性完成收割、脱粒，并将谷粒集中到储藏仓，并输送到运输车上。收割机的主要结构有割台，输送带，脱粒机，集粮仓等",
          id: 4,
          img: require("@/assets/images/service04.png"),
        },
      ], // 一体化数据
      unify_index: 0, // 一体化默认 - 1
      honor_list: [
        {
          name: "河南农业产业化省重点龙头企业",
          img: "https://mp-resource.shouyinongye.com/resource/shouyi/pc/honor5.png",
        },
        {
          name: "河南省农业生产社会化服务示范组织",
          img: "https://mp-resource.shouyinongye.com/resource/shouyi/pc/honor2.png",
        },
        {
          name: "全国星级农业科技社会化服务组织",
          img: "https://mp-resource.shouyinongye.com/resource/shouyi/pc/honor3.png",
        },
        {
          name: "全国农业社会化服务创新试点组织",
          img: "https://mp-resource.shouyinongye.com/resource/shouyi/pc/honor4.png",
        },
      ], // 荣誉数据
      show_right: false, // 是否展示右侧数据
      img: {
        logo: `${resource_url}/website/logo.png`,
        play: `${resource_url}/website/play.png`,
        arrow: `${resource_url}/website/arrow.png`,
        phone: `${resource_url}/website/phone.png`,
        loc: `${resource_url}/website/loc.png`,
        logoFt: `${resource_url}/website/logo-ft.png`,
        diqiu: `${resource_url}/website/diqiu.png`,
        bnBg: `${resource_url}/website/bn-bg.png`,
        sph: `${resource_url}/website/sph.png`, //视频号二维码,
        gzh: `${resource_url}/website/gzh.png`, //公众号,
        qywx: `${resource_url}/website/qywx.png`, //企业微信,
      },
      carousel: [
        {
          src: require("@/assets/images/lb07.png"),
          info: "代耕服务：一站式托管的保姆式土地耕种模式。提供整地，播种，施肥，打药，收割，运输等一体化、精细化全程服务。<br/>饲料贸易：青储饲料产业化，提供小麦秸秆、首蓉、青储玉米等青储机械化收割，裹包业务。<br/>农资贸易：提供种子、化肥、农药等农资用品零售，批发等贸易。",
        },
        {
          src: require("@/assets/images/lb04.png"),
          info: "将信息作为农业生产要素，用现代信息技术对农业对象、环境和全过程进行可视化表达、数字化设计、信息化管理的现代农业。数字农业使信息技术与农业各个环节实现有效融合，对改造传统农业、转变农业生产方式具有重要意义。",
        },
        {
          src: require("@/assets/images/lb05.png"),
          info: "以信息技术为支撑，根据空间变异，定位、定时、定量地实施一整套现代化农事操作与管理的系统，是信息技术与农业生产全面结合的一种新型农业。精准农业是近年出现的专门用于大田作物种植的综合集成的高科技农业应用系统。",
        },
        {
          src: require("@/assets/images/lb06.png"),
          info: "智能化农业是一种拥有高层次、多方面农业专家知识、并能模仿人类的推理过程，在计算机或其他智能终端上以形象、直观的方式向使用者或政策制定者提供各种农业问题咨询服务的实用系统或智能化农业设施。",
        },
      ],
      carousel_Index: 1,
      company_info: [
        { title: "首邑成立于", info: "2016", unit: "年" },
        { title: "托管服务土地 15 万余亩", info: "2019", unit: "年" },
        { title: "托管服务土地 30 万余亩", info: "2020", unit: "年" },
        { title: "托管服务土地 62 万余亩", info: "2023", unit: "年" },
      ],
      first_news: {}, //第一条新闻
      news_list: [], //新闻列表
      form: {
        company_name: "",
        contact: "",
        mobile: "",
        cooperation_id: "",
        message: "",
      },
      orgForm: {},
      rules: {
        company_name: [
          {
            required: true,
            message: "公司名称不能为空",
          },
        ],
        contact: [
          {
            required: true,
            message: "姓名不能为空",
          },
        ],
        mobile: [
          {
            required: true,
            message: "请输入正确格式的手机号码",
          },
        ],
        cooperation_id: [
          {
            required: true,
            message: "合作类型不能为空",
            trigger: "change",
          },
        ],
        message: [
          {
            required: true,
            message: "留言不能为空",
          },
        ],
      },
      active_index: -1, //点击上方锚点
      typeList: [], //合作类型
      is_play: false,
      setting: {
        company_name: "",
        company_address: "",
        company_email: "",
        company_mobile: "",
        company_latitude: "",
        company_longitude: "",
      }, // 固定配置
    };
  },
  components: {
    ComHeader,
    ComFooter,
    HomeBusiness,
    HomeEnterpriseHonor,
    CompanyMap,
  },
  methods: {
    // 切换一体化服务数据
    changeUnify(index) {
      if (index == this.unify_index) return;
      this.unify_index = index;
    },
    // 看更多荣誉
    moreImg(type) {
      if (type == 1) {
        this.show_right = true;
      } else {
        this.show_right = false;
      }
    },
    //轮播图change事件
    carouselChange(index) {
      this.carousel_Index = index + 1;
    },
    //点击跳转至对应区域
    clickMenu(e) {
      const { index, item } = e;
      window.removeEventListener("scroll", this.onScrollEvent);
      if (index == 0) {
        this.active_index = -1;
      } else {
        this.active_index = index;
      }
      if (item.href) {
        window.open(item.href, "_blank");
      } else {
        const scrollTop = this.$refs[item.ref].offsetTop - 80;
        window.scrollTo({
          top: scrollTop,
          behavior: "smooth",
        });
        const timer1 = setTimeout(() => {
          window.addEventListener("scroll", this.onScrollEvent, false);
          clearTimeout(timer1);
        }, 1000);
      }
    },
    //滚动事件
    onScrollEvent() {
      const scroll_top =
        document.documentElement.scrollTop || document.body.scrollTop;
      const offset_top_arr = menu_list.map((item) => {
        if (this.$refs[item.ref]) {
          return this.$refs[item.ref].offsetTop - 150;
        }
      });
      offset_top_arr.forEach((item, index) => {
        if (scroll_top >= item) {
          if (index == 0) {
            this.active_index = -1;
          } else {
            this.active_index = index;
          }
        }
      });
      const contact_top =
        this.$refs["contact"].offsetTop +
        this.$refs["contact"].offsetHeight -
        150;
      if (scroll_top > contact_top) {
        this.active_index = -1;
      }
    },
    //初始化默认按钮
    setDefaultActiveMenu() {
      let menu_info = localStorage.getItem("menu_info");
      menu_info = menu_info ? JSON.parse(menu_info) : null;
      if (menu_info) {
        this.clickMenu(menu_info);
        localStorage.removeItem("menu_info");
      }
    },
    //跳转新闻详情
    goNewsDetail(item) {
      const { news_id, type, url } = item;
      if (type == 2) {
        window.open(url, "_blank");
      } else {
        this.$router.push({ name: "news-detail", query: { news_id } });
      }
    },
    goPage(name) {
      this.$router.push({ name });
    },
    //获取合作类型下拉列表
    getSelectOptions() {
      getCoopreationOptions({ type: 2 }).then((res) => {
        this.typeList = res.data.data;
      });
    },
    // 提交表单
    submitForm(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          saveCoopreationData(this.form).then(() => {
            this.$message.success("提交成功");
            this.$refs.form.resetFields();
          });
        } else {
          return false;
        }
      });
      return false;
    },
    // 播放视频
    play() {
      this.is_play = true;
      this.$refs.video.play();
    },
    // 关闭视频播放弹窗
    closeDialog() {
      this.$refs.video.pause();
      this.is_play = false;
    },
    inputPhone(val) {
      const reg = /^\d{1,20}$/;
      if (!reg.test(val)) {
        this.form.mobile = "";
      }
    },
    blurPhone() {
      const reg = /^\d{11,20}$/;
      const regCN = /^1[0-9]{10}$/; //^1[3|4|5|7|8][0-9]{9}$
      if (this.form.mobile.length === 11 && !regCN.test(this.form.mobile)) {
        this.form.mobile = "";
      }
      if (!reg.test(this.form.mobile)) {
        this.form.mobile = "";
      }
    },
    //获取新闻列表
    getHomeNewsList() {
      const params = {
        page: 1,
        size: 4,
      };
      getNewsList(params).then((res) => {
        const { list } = res.data.data;
        if (list.length) {
          list.forEach((item) => {
            item.created_at = item.created_at.split(" ")[0] || "--";
          });
          this.first_news = list[0];
          if (list.length > 1) {
            let len = list.length >= 3 ? 3 : list.length;
            this.news_list = list.splice(1, len);
          }
        }
      });
    },
    // 获取固定配置
    async getHomeFixedConfig() {
      try {
        const params = { params_name: "company" };
        const { data } = await getFixedConfig(params);
        this.setting = data.data;
      } catch (error) {
        console.error("getHomeFixedConfig err", error);
      }
    },
  },
  mounted() {
    this.getHomeFixedConfig();
    this.orgForm = JSON.parse(JSON.stringify(this.form));
    this.getSelectOptions();
    this.getHomeNewsList();
    this.setDefaultActiveMenu();
    window.addEventListener("scroll", this.onScrollEvent, false);
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScrollEvent);
  },
};
</script>

<style lang="scss" scoped>
.website-wrap {
  background-color: #fff;
  transition: all 0.5s;
}
.home-container {
  padding-top: 140px;
  .middle-content {
    max-width: 1200px;
    margin: 30px auto 0;
  }
  .video-fill {
    height: calc(100vh - 140px);
    z-index: 111;
    overflow: hidden;
    background: url("https://mp-resource.shouyinongye.com/resource/website/bar3.jpeg")
      no-repeat center center;
    position: relative;
    & > video {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
    .img-title {
      top: 0;
      left: 0;
      width: 100%;
      z-index: 10;
      height: 222px;
      bottom: 0;
      margin: auto;
      position: absolute;
      right: 0;
      .img-title-main {
        text-align: center;
        h2 {
          &:first-child {
            line-height: 72px;
            margin-bottom: 20px;
          }
          font-size: 52px;
          line-height: 60px;
          color: #fff;
        }
      }
    }
    .play {
      font-size: 60px;
      margin-top: 35px;
      color: #fff;
      text-align: center;
      img {
        cursor: pointer;
      }
    }
    .scroll {
      width: 144px;
      background-size: 100% 100%;
      bottom: 9.47368%;
      left: 0;
      margin: auto;
      font-size: 12px;
      color: #fff;
      position: absolute;
      right: 0;
      span {
        font-size: 12px;
        color: #fff;
        position: absolute;
        width: 25px;
        height: 25px;
        &:first-child {
          top: 4px;
          left: 0;
          background: url("https://mp-resource.shouyinongye.com/resource/website/shubiao.png")
            no-repeat center center;
        }
        &:last-child {
          top: 4px;
          right: 0;
          background: url("https://mp-resource.shouyinongye.com/resource/website/down.png")
            no-repeat center center;
        }
      }
      div {
        position: absolute;
        left: 25px;
        right: 25px;
        text-align: center;
        top: 6px;
        bottom: 0;
      }
    }
    .banner-bg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .middle {
    width: 100%;
    background: url("https://mp-resource.shouyinongye.com/resource/website/l-bg.png")
      0 229px no-repeat;
    background-size: 267px 550px;
  }
  .moduel {
    padding-top: 100px;
    &.agriculture {
      width: 100%;
      .moduel-title p {
        background: linear-gradient(to bottom, #fff, #b5ecc7);
        background-clip: text;
        color: transparent;
      }
      .service-list {
        flex-wrap: wrap;
        margin-top: 63px;
        box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.05);
        .service-item {
          width: 300px;
          height: 200px;
          .text {
            margin-top: 46px;
            margin-left: 24px;
          }
          .service-name {
            font-size: 24px;
            font-weight: 500;
            color: #3bc55c;
            line-height: 33px;
          }
          .service-img {
            width: 100%;
            height: 100%;
          }
          .service-info {
            width: 258px;
            font-size: 18px;
            color: #201f1f;
            line-height: 25px;
            margin-top: 25px;
            margin-left: 24px;
            text-align: left;
          }
          .arrow-left,
          .arrow-right {
            width: 15px;
            height: 18px;
            margin-right: 12px;
          }
          .arrow-right {
            margin-left: 12px;
          }
        }
      }
    }
    &.aboutme {
      .moduel-main {
        max-width: 1200px;
        margin: 30px auto 0;
        .about-item {
          .module-left {
            max-width: 540px;
            .company-info {
              text-align: left;
              font-size: 14px;
              font-weight: 400;
              color: #201f1f;
              line-height: 20px;
            }
            .info-list {
              padding: 48px 30px 0 0;
              box-sizing: border-box;
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              .info-item {
                text-align: center;
                width: 210px;
                margin-bottom: 50px;
                &:nth-child(3),
                &:nth-child(4) {
                  margin-bottom: 0;
                }
                .year {
                  .year-number {
                    display: inline-block;
                    height: 59px;
                    font-size: 44px;
                    color: #201f1f;
                    line-height: 60px;
                    font-weight: 600;
                  }
                  .unit {
                    display: inline-block;
                    margin-left: 4px;
                    height: 20px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #201f1f;
                    line-height: 60px;
                  }
                }
                .event {
                  height: 20px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #201f1f;
                  line-height: 20px;
                  margin-top: 2px;
                }
              }
            }
            .sub-title {
              width: 144px;
              margin-top: 147px;
              height: 50px;
              font-size: 36px;
              font-weight: 600;
              color: #383737;
              line-height: 50px;
              background: linear-gradient(to bottom, #fff 50%, #01441e 100%);
            }
            .service-province,
            .total-service {
              font-size: 18px;
              color: #201f1f;
              margin-top: 30px;
              text-align: left;
              line-height: 25px;
            }
            .total-service {
              margin-top: 24px;
            }
          }
          .module-right {
            img {
              width: 600px;
              height: 400px;
            }
            .map-img {
              width: 635px;
              height: 478px;
            }
          }
        }
        ::v-deep .el-carousel__indicator {
          .el-carousel__button {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #01441e;
            opacity: 0.2;
          }
          &.is-active .el-carousel__button {
            opacity: 1;
          }
        }
      }
    }
    &.integrated-service {
      padding-top: 100px;
      margin-bottom: 70px;
      .moduel-title::after {
        display: none;
      }
      .middle-content {
        max-width: 1200px;
        margin: 30px auto 0;
        height: 394px;
        background: #ffffff;
        box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.05);
        .service-list {
          display: flex;
          justify-content: space-between;
          font-size: 18px;
          .name {
            font-weight: bold;
            color: #201f1f;
          }
          .list-left {
            font-size: 18px;
            .left-item {
              height: 100px;
              padding: 0 33px 0 22px;
              display: flex;
              align-items: center;
              border: 1px solid #f5f8f6;
              cursor: pointer;
              position: relative;
              .item-icon {
                width: 40px;
                height: 40px;
                margin-right: 16px;
              }
              .item-text {
                font-family: PingFang-SC-Regular, PingFang-SC;
                font-weight: 400;
                color: #a5a5a5;
                margin-top: 5px;
              }
            }
            .active-bor {
              border-right: 4px solid #22834c;
            }
          }
          .list-right {
            display: flex;
            flex: 1;
            justify-content: space-between;
            .infos {
              padding-top: 100px;
              padding-left: 63px;
              position: relative;
              flex: 1;
              .spot {
                width: 6px;
                height: 6px;
                background: #22834c;
                border-radius: 50%;
                margin-right: 10px;
              }
              .container {
                text-align: left;
                margin-top: -5px;
              }
              .content {
                width: 341px;
                margin-top: 16px;
                color: #414141;
                font-size: 16px;
                line-height: 22px;
              }
              .num {
                position: absolute;
                right: 37px;
                top: 27px;
                width: 98px;
                height: 94px;
              }
              .tip {
                position: absolute;
                bottom: 37px;
                left: 80px;
                color: #d7ddd9;
              }
            }
            .right-img {
              width: 396px;
              height: 394px;
            }
          }
          .service-item {
            width: 270px;
            height: 300px;
            background: #ffffff;
            box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.05);
            position: relative;
            .item-img {
              width: 270px;
              height: 152px;
            }
            .number {
              height: 79px;
              font-size: 60px;
              font-family: Bebas;
              color: #f5f8f6;
              line-height: 79px;
              position: absolute;
              bottom: 0;
              right: 6px;
            }
            .service-bottom {
              width: 100%;
              height: 148px;
              position: absolute;
              bottom: 0;
              left: 0;
              .service-name {
                text-align: left;
                padding: 20px 16px 0 20px;
                .icon {
                  width: 17px;
                  height: 17px;
                  vertical-align: text-bottom;
                  margin-right: 9px;
                }
                .name {
                  display: inline-block;
                  height: 22px;
                  font-size: 16px;
                  font-weight: 500;
                  color: #201f1f;
                  line-height: 22px;
                }
              }
              .service-info {
                margin-top: 10px;
                font-size: 12px;
                font-weight: 400;
                color: #414141;
                line-height: 17px;
                padding: 0 16px 27px 20px;
                text-align: left;
              }
            }
          }
        }
      }
    }
    &.news {
      width: 100%;
      height: 926px;
      background: #f8f8f8;
      margin-top: 100px;
      padding-top: 100px;
      .middle-content {
        max-width: 1200px;
        margin: 60px auto 0;
        display: flex;
        justify-content: space-between;
        text-align: left;
        position: relative;
        .left-news {
          width: 518px;
          background: #ffffff;
          box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.03);
          cursor: pointer;
          .news-img {
            width: 518px;
            height: 347px;
            overflow: hidden;
            img {
              width: 518px;
              height: 347px;
              vertical-align: bottom;
              transition: transform 0.8s;
              &:hover {
                transform: scale(1.08);
              }
            }
          }
          .title {
            width: 100%;
            padding: 20px 22px 0;
            font-size: 18px;
            font-weight: 600;
            color: #201f1f;
            line-height: 31px;
            transition: all 0.3s;
            &:hover {
              color: #22834c;
            }
          }
          .bottom-info {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 26px 22px 20px;
            .plulish-date {
              font-size: 14px;
              font-weight: 400;
              color: #545454;
            }
            .read-more {
              font-size: 14px;
              font-weight: 600;
              color: #22834c;
              cursor: pointer;
            }
          }
        }
        .right-news {
          width: 662px;
          position: relative;
          .news-item {
            margin-bottom: 20px;
            width: 662px;
            height: 176px;
            background: #ffffff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 36px;
          }
          .new-img {
            width: 220px;
            height: 176px;
          }
          .infos {
            width: 366px;
            margin-left: 30px;
            cursor: pointer;
            &:hover {
              .news-title {
                color: #22834c;
              }
            }
            &:last-child {
              border-bottom: 0;
            }
            &:first-child {
              margin-top: 0;
            }
            .news-title {
              font-size: 18px;
              font-weight: 600;
              color: #201f1f;
              line-height: 25px;
              transition: color 0.3s;
            }
            .content {
              font-size: 16px;
              font-weight: 400;
              color: #414141;
              line-height: 28px;
              margin-top: 10px;
            }
            .news-bottom {
              margin-top: 25px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .date {
                font-size: 14px;
                color: #a5a5a5;
              }
              .read-more {
                font-size: 14px;
                font-weight: 600;
                color: #22834c;
                cursor: pointer;
              }
            }
          }
          .look-more {
            position: absolute;
            bottom: -36px;
            right: 0;
            text-align: center;
            height: 25px;
            font-size: 18px;
            font-weight: 600;
            color: #22834c;
            line-height: 25px;
            margin-top: 40px;
            cursor: pointer;
            ::after {
              position: absolute;
              content: "MORE";
              top: -31px;
              left: -100px;
              width: 116px;
              height: 74px;
              font-size: 62px;
              font-family: Bebas-Regular, Bebas;
              font-weight: 400;
              color: rgba(34, 131, 76, 0.08);
              line-height: 74px;
            }
            img {
              width: 13px;
              height: 14px;
              margin-left: 5px;
            }
          }
        }
      }
    }
    &.cooperation {
      .moduel-main {
        padding: 60px 0 198px 0;
        background: url("https://mp-resource.shouyinongye.com/resource/website/r-bg.png")
            no-repeat right 233px,
          url("https://mp-resource.shouyinongye.com/resource/website/join.jpg")
            no-repeat center bottom;
        background-size: 268px 453px, 100% 485px;
        text-align: center;
        .form {
          text-align: left;
          width: 1200px;
          padding: 80px 320px;
          display: inline-block;
          background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
          box-shadow: 0px 0px 64px 0px rgba(0, 0, 0, 0.07);
          ::v-deep .el-form-item--small.el-form-item {
            margin-bottom: 0;
            & + .el-form-item--small.el-form-item {
              margin-top: 45px;
            }
          }
          ::v-deep .el-input__inner:focus {
            border-color: #286141;
          }
          ::v-deep .el-form-item__label {
            color: #000;
            font-size: 18px;
            line-height: 38px;
          }
          ::v-deep .el-select {
            width: 100%;
          }
          ::v-deep .el-input--small .el-input__inner {
            height: 44px;
            line-height: 44px;
            width: 100%;
            font-size: 18px;
          }
          ::v-deep .el-button {
            width: 242px;
            height: 48px;
            background: #22834c;
            border-radius: 6px;
            font-size: 20px !important;
            display: block;
            margin: 0 auto;
            border: 0;
          }
        }
        ::v-deep .el-icon-arrow-up {
          font-size: 22px;
          position: relative;
          right: 12px;
        }
      }
    }
    &.contact {
      .location-img {
        width: 1200px;
        height: 460px;
        text-align: center;
        margin-top: 100px;
      }
      .moduel-main {
        max-width: 1200px;
        margin: 40px auto 0;
        padding: 40px 0;
        display: flex;
        justify-content: space-between;
        background: #f5f8f6;
        .split-line {
          width: 1px;
          height: 150px;
          background: #ccdbd1;
          margin-top: 51px;
        }
        .item {
          width: 33.3%;
          text-align: center;
          padding: 40px 0;
          flex: 1;
          img {
            width: 100px;
            height: 100px;
          }
          .title {
            height: 22px;
            font-size: 16px;
            font-weight: 600;
            color: #383737;
            line-height: 22px;
            margin-top: 20px;
          }
          span {
            display: block;
            height: 20px;
            font-size: 14px;
            color: #383737;
            line-height: 20px;
            margin-top: 10px;
          }
        }
      }
    }
  }
  .feed-trade {
    margin-bottom: 70px;
  }
  .moduel-title {
    height: 110px;
    text-align: center;
    position: relative;
    span {
      display: block;
      position: absolute;
      top: 0px;
      font-size: 40px;
      color: #383737;
      height: 56px;
      line-height: 56px;
      left: 50%;
      transform: translateX(-50%);
      font-weight: bold;
    }
    p {
      padding-top: 14px;
      font-family: "website";
      font-size: 52px;
      line-height: 69px;
      background: linear-gradient(#fff, #f2f2f2);
      background-clip: text;
      color: transparent;
      -webkit-text-fill-color: transparent;
      font-weight: bold;
    }
    &::after {
      position: absolute;
      content: "";
      left: 50%;
      transform: translateX(-50%);
      width: 48px;
      height: 6px;
      background: #01441e;
      bottom: 0;
    }
  }
  .elegant-demeanor {
    width: 100%;
    margin-top: 100px;
    position: relative;
    .elegant-carousel {
      width: 100%;
      height: auto;
      ::v-deep .el-carousel__container,
      .el-carousel--horizontal {
        width: 100%;
        height: 807px;
        img {
          width: 100%;
          height: 807px;
        }
      }
    }
    .elegant-title {
      position: absolute;
      z-index: 100;
      left: 50%;
      top: 60px;
      transform: translate(-50%, 0);
      span {
        color: #fff;
      }
      p {
        padding-top: 14px;
        font-family: "website";
        font-size: 52px;
        line-height: 69px;
        color: #fff;
        opacity: 0.3;
        font-weight: bold;
      }
    }
  }
}
.screen {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3000;
  .el-icon-close {
    position: absolute;
    top: 50px;
    right: 50px;
    color: #fff;
    font-size: 50px;
    cursor: pointer;
  }
}
</style>
